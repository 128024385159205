import React from 'react';
import Link from 'next/link';
import TextLoop from 'react-text-loop';
import PropTypes from 'prop-types';
import SocialLinks from '@components/Footer/SocialLinks';
import { CircleDollarIcon, CompanyLogoIcon, HeartIcon } from '@components/Icons';

const FOOTER_CITIES = [{
  slug: 'san-francisco',
  name: 'San Francisco',
  state: 'california',
}, {
  slug: 'new-york',
  name: 'New York City',
  state: 'new-york',
}, {
  slug: 'los-angeles',
  name: 'Los Angeles',
  state: 'california',
}, {
  slug: 'seattle',
  name: 'Seattle',
  state: 'washington',
}, {
  slug: 'boston',
  name: 'Boston',
  state: 'massachusetts',
}, {
  slug: 'philadelphia',
  name: 'Philadelphia',
  state: 'pennsylvania',
}, {
  slug: 'dallas',
  name: 'Dallas',
  state: 'texas',
}, {
  slug: 'nashville',
  name: 'Nashville',
  state: 'Tennessee',
}];

const Footer = ({ wrapperClassName }) => (
  <footer className={`bg--cover footer relative z--1 p-top--x-large ${wrapperClassName}`}>
    <div className="mw--md m-horizontal--auto p-around--large p-around--x-large-gt-xs layout-gt-xs-row layout-align-gt-xs-start-start layout-wrap">
      <div className="p-right--medium flex-sm-100 flex-gt-sm layout-column">
        <Link href="/">
          <a aria-label="Kopa home" className="hover-none">
            <CompanyLogoIcon className="block h--48 w--auto" />
          </a>
        </Link>
        <p className="m-top--small">Get out there. Find your place.</p>
      </div>
      <div className="m-top--large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Hosting</h3>
        <Link href="/host">
          <a className="black m-top--x-small">Become a Host</a>
        </Link>
        <Link href="/direct-booking-pro">
          <a className="black m-top--x-small">Direct Booking Pro</a>
        </Link>
        <Link href="/refer-host">
          <a className="inline-flex black m-top--x-small">
            Refer a Host
            <CircleDollarIcon className="icon--16 m-left--xx-small" />
          </a>
        </Link>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Renting</h3>
        <Link href="/c">
          <a className="black m-top--x-small">Browse Rentals</a>
        </Link>
        <Link href="/housemate-finder">
          <a className="black m-top--x-small">Find Roommates</a>
        </Link>
        <Link href="/for-business">
          <a className="black m-top--x-small">For Business</a>
        </Link>
        <Link href="/for-universities">
          <a className="black m-top--x-small">For Universities</a>
        </Link>
        <Link href="/refer-renter">
          <a className="inline-flex black m-top--x-small">
            Refer a Renter
            <CircleDollarIcon className="icon--16 m-left--xx-small" />
          </a>
        </Link>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Resources</h3>
        <Link href="/about">
          <a className="black m-top--x-small">About Us</a>
        </Link>
        <Link href="/careers">
          <a className="black m-top--x-small">Careers</a>
        </Link>
        <a className="black m-top--x-small" href="https://www.kopa.co/blog" rel="noopener noreferrer" target="_blank">Blog</a>
        <Link href="/partnerships">
          <a className="black m-top--x-small">Partner Program</a>
        </Link>
        <a className="black m-top--x-small" href="/contact">Contact Us</a>
        <a className="black m-top--x-small" href="https://help.kopa.co/hc/en-us/" rel="noopener noreferrer" target="_blank">Help Center</a>
        <a className="black m-top--x-small" href="/sitemap">Sitemap</a>
      </div>
      <div className="m-top--x-large-lt-md p-right--medium-gt-xs flex-sm-50 flex-gt-sm-15 layout-column">
        <h3 className="m-bottom--small">Top Cities</h3>
        { FOOTER_CITIES.map((city) => (
          <Link href={`/c/${city.state}/${city.slug}`} key={city.slug}>
            <a className="black m-top--x-small">{ city.name }</a>
          </Link>
        ))}
      </div>
    </div>
    <div className="mw--md m-horizontal--auto b-top--xs b--gray-2 p-around--large p-horizontal--x-large-gt-xs p-vertical--medium-gt-xs layout-gt-sm-row layout-align-gt-sm-space-between-center">
      <div className="meta layout-row layout-align-start-center layout-wrap">
        <span className="meta dot-separator">© Kopa 2021</span>
        <div className="layout-row layout-align-start-center">
          Made with
          <HeartIcon className="icon--16 icon--hue-2 inline-block m-horizontal--xx-small" />
        </div>
        <div className="layout-row layout-align-start-center">
          by people from
          <TextLoop className="m-left--xx-small" interval="2000" springConfig={{stiffness: 500, damping: 26}}>
            <span>America</span>
            <span>Canada</span>
            <span>Pakistan</span>
          </TextLoop>
        </div>
      </div>
      <div className="m-top--medium-lt-sm layout-row layout-align-start-center layout-wrap">
        <span className="dot-separator" id="footer-tos">
          <Link href="/terms">
            <a className="meta roman black">Terms of Service</a>
          </Link>
        </span>
        <span className="m-right--medium" id="footer-privacy">
          <Link href="/privacy">
            <a className="meta roman black">Privacy Policy</a>
          </Link>
        </span>
        <SocialLinks />
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  wrapperClassName: PropTypes.string,
};

Footer.defaultProps = {
  wrapperClassName: '',
};

export default Footer;

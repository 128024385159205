import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';

const HeadComponent = ({ metaFavicon, metaTitle, metaDescription, metaImage, metaImageTwitter, metaCanonical, metaUrl, metaSchema, noIndex }) => (
  <>
    <Head>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content="text/html; charSet=utf-8" httpEquiv="Content-Type" />

      { noIndex ? (
        <meta content="noindex" name="robots" />
      ) : (
        <meta content="index, follow" name="robots" />
      )}

      <meta content="2 days" name="revisit-after" />
      <meta content="English" name="language" />
      <meta content="#185C97" name="theme-color" />

      <link href={metaFavicon} rel="shortcut icon" />

      { /* Common Tags */ }
      <meta charSet="utf-8" />
      <title>{metaTitle}</title>

      { /* Search Engine */ }
      <meta content={metaDescription} name="description" />

      { /* Schema.org for Google */ }
      <meta content={metaTitle} itemProp="name" />
      <meta content={metaDescription} itemProp="description" />

      { /* Twitter */ }
      <meta content="summary" name="twitter:card" />
      <meta content={metaTitle} name="twitter:title" />
      <meta content={metaDescription} name="twitter:description" />
      <meta content="@kopahq" name="twitter:site" />
      <meta content={metaUrl} name="twitter:url" />
      <meta content={metaImageTwitter || metaImage} name="twitter:image" />
      <meta content="summary_large_image" name="twitter:card" />

      { /* Open Graph general (Facebook, Pinterest & Google+) */ }
      <meta content="1671188829873050" property="fb:app_id" />
      <meta content={metaTitle} property="og:title" />
      <meta content={metaDescription} property="og:description" />
      <meta content="website" property="og:type" />
      <meta content="Kopa" property="og:site_name" />
      <meta content={metaUrl} property="og:url" />
      <meta content={metaImage} property="og:image" />

      { /* Fonts */ }
      <link href="https://use.typekit.net/zhh1ipo.css" rel="stylesheet" />

      {/* Dynamic */}
      { metaCanonical && (
        <link href={metaCanonical} rel="canonical" />
      )}
      { metaSchema && (
        <script
          dangerouslySetInnerHTML={{ __html: JSON.stringify(metaSchema)}}
          type="application/ld+json"
        />
      )}

      {/* Analytics */}
      { process.env.SEGMENT_API_KEY && (
        <Script dangerouslySetInnerHTML={{
          __html: `
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
              analytics.load("${process.env.SEGMENT_API_KEY}");
              }}();
            `,
        }}
        />
      )}

      {/* Google maps */}
      <script key="googleMapsScript" src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`} />

      {/* Plaid */}
      <script defer key="plaidScript" src="https://cdn.plaid.com/link/v2/stable/link-initialize.js" />
    </Head>
      
    {/* IE */}
    <Script
      dangerouslySetInnerHTML={{
        __html: `!function(){
      if(typeof window === 'undefined') return
      var ua = window.navigator.userAgent;
      var isIE = /MSIE|Trident/.test(ua);
      if (isIE) {
        alert("Uh oh! This website doesn't work in Internet Explorer. Please try accessing the website on Chrome, Edge, Firefox, or Safari.");
      }
    }()`,
      }}
      id="internet-explorer-check"
      strategy="beforeInteractive"
    />

  </>
);

HeadComponent.propTypes = {
  // Optional
  metaFavicon: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaUrl: PropTypes.string,
  metaCanonical: PropTypes.string,
  metaImage: PropTypes.string,
  metaImageTwitter: PropTypes.string,
  metaSchema: PropTypes.object,
  noIndex: PropTypes.bool,
};

HeadComponent.defaultProps = {
  metaFavicon: 'https://static-images.kopa.co/logos/favicon.png',
  metaTitle: 'Kopa: Monthly Rentals and Roommates',
  metaDescription: 'With Kopa, renters find rentals and roommates for extended stays. Book and pay for furnished rentals, sublets, and rooms for rent.',
  metaUrl: process.env.ESPRESSO_BASE,
  metaCanonical: null,
  metaImage: 'https://static-images.kopa.co/thumbnails/kopa-furnished-rentals-and-roommates-1200x630.jpg',
  metaImageTwitter: 'https://static-images.kopa.co/thumbnails/kopa-furnished-rentals-and-roommates-600x315.jpg',
  metaSchema: null,
  noIndex: false,
};

export default HeadComponent;
